import React, { useRef } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface EditorProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  limit?: number
}

const QuillEditor: React.FC<EditorProps> = ({
  value,
  onChange,
  placeholder,
  limit,
}) => {
  const quillRef = useRef<ReactQuill | null>(null)
  const handleChange = (
    content: string,
    delta: unknown,
    source: unknown,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    const plainText = editor.getText().trim()
    if (source === 'user') {
      if (limit && plainText.length > limit) {
        const currentEditor = quillRef.current?.getEditor()
        if (currentEditor) {
          const truncatedContent = plainText.slice(0, limit)
          const delta = currentEditor.clipboard.convert(truncatedContent)
          currentEditor.setContents(delta, 'silent')
        }
      } else {
        onChange(editor.getHTML())
      }
    }
  }

  const plainTextLength = value.replace(/<[^>]*>/g, '').length

  return (
    <div>
      <ReactQuill
        className="bg-white"
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        ref={quillRef}
      />
      {limit && (
        <div className="text-right text-sm text-gray-500">
          {plainTextLength}/{limit} characters
        </div>
      )}
    </div>
  )
}

export default QuillEditor
