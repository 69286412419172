import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'

import Carousel from 'components/carousel/Carousel'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { formatPrice } from 'utils/helper'

type PreviewProduct = {
  name: string
  price?: number
  discountedPrice?: number
  variants: {
    id?: number
    type: string
    name: string
    price: number
    discountedPrice: number
  }[]
  description?: string
}
interface PreviewProps {
  files?: string[]
  product: PreviewProduct
}

const Preview: React.FC<PreviewProps> = ({ files, product }): JSX.Element => {
  const [variantSelect, setVariantSelect] = useState({
    color: '',
    size: '',
    price: 0,
    discountedPrice: 0,
  })
  const { name, price, discountedPrice, variants, description } = product

  const colorVariants = variants.filter((v) => v.type === 'color')
  const sizeVariants = variants.filter((v) => v.type === 'size')

  const priceData = price
  // if (typeof price === 'string' && price !== '') priceData = price
  // else if (typeof discountedPrice === 'number' && price !== 0) priceData = price

  const discountedPriceData = discountedPrice
  // if (typeof discountedPrice === 'string' && discountedPrice !== '')
  //   discountedPriceData = discountedPrice
  // else if (typeof discountedPrice === 'number' && discountedPrice !== 0)
  //   discountedPriceData = discountedPrice?.toString()

  return (
    <Box sx={{ flex: 1 }}>
      <h2 className=" text-lg font-bold mb-2">{name}</h2>
      {files && files.length > 0 && <Carousel files={files} />}
      {priceData && discountedPriceData && (
        <div>
          <span className=" mr-2">
            <del>${formatPrice(priceData)}</del>
          </span>
          <span className=" text-lg font-semibold">
            {formatPrice(discountedPriceData)}
          </span>
        </div>
      )}
      {variants.length > 0 && (
        <>
          {variantSelect.price && variantSelect.discountedPrice && (
            <div className="mb-2">
              <span className=" mr-2">
                <del>{formatPrice(variantSelect.price)}</del>
              </span>
              <span className=" text-lg font-semibold">
                {formatPrice(variantSelect.discountedPrice)}
              </span>
            </div>
          )}
          <div>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Color
            </Typography>
            {colorVariants.map((variant) => (
              <button
                type="button"
                key={variant.name}
                className={`bg-gray-200 text-sm rounded-xl py-1 px-3 hover:bg-gray-300 mr-2 active: ${variant.name === variantSelect.color ? 'bg-gray-300' : ''}`}
                onClick={() =>
                  setVariantSelect({
                    color: variant.name,
                    size: '',
                    price: variant.price,
                    discountedPrice: variant.discountedPrice,
                  })
                }
              >
                {variant.name}
              </button>
            ))}
          </div>
          {sizeVariants.length > 0 && (
            <div className="mt-2">
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Size
              </Typography>
              {sizeVariants.map((variant) => (
                <button
                  type="button"
                  key={variant.name}
                  className={`bg-gray-200 text-sm rounded-xl py-1 px-3 hover:bg-gray-300 mr-2 active: ${variant.name === variantSelect.size ? 'bg-gray-300' : ''}`}
                  onClick={() =>
                    setVariantSelect((prev) => ({
                      ...prev,
                      size: variant.name,
                      price: variant.price,
                      discountedPrice: variant.discountedPrice,
                    }))
                  }
                >
                  {variant.name}
                </button>
              ))}
            </div>
          )}
        </>
      )}

      {description && (
        <div className="mt-2">
          <TextCollapse text={description} />
        </div>
      )}
    </Box>
  )
}

export default Preview
