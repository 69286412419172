import { ChangeEvent, FC, useEffect, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import DOMPurify from 'dompurify'

import Card from 'components/card'
import SingleImageCropper from 'components/files/SingleFileCropDialog'
import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { deleteFile, getPresignedUrl, uploadFile } from 'services/files'
import { updateOrganization } from 'services/organization'
import { validateProductImage } from 'utils/validation'

interface AboutProps {
  description?: string | null
  imageUrl?: string | null
}
const fileUrl = process.env.REACT_APP_FILE_URL

const About: FC<AboutProps> = ({ imageUrl, description }) => {
  const [descriptionValue, setDescriptionValue] = useState('')
  const [image, setImage] = useState<string | null | undefined>(null)
  const [error, setError] = useState<{ type: string; message: string } | null>(
    null
  )
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number
    fileName: string
  }>({
    progress: 0,
    fileName: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [cropFile, setCropFile] = useState<File | null>(null)
  const [showCropper, setShowCropper] = useState(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (imageUrl) {
      setImage(imageUrl)
    }
    if (description) {
      setDescriptionValue(description)
    }
  }, [imageUrl, description])

  const handleSave = async (file: File): Promise<void> => {
    setIsUploading(true)
    const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
    setUploadProgress({ progress: 0, fileName: file.name })

    const result = await uploadFile(file, signedUrl, (progress) => {
      setUploadProgress({ progress, fileName: file.name })
    })

    if (result.status === 'successful') {
      const imageUrl = `${fileUrl}/${uuid}`
      const res = organizationId
        ? await updateOrganization(organizationId, { imageUrl })
        : { status: 'failed' }

      if (res && res.status === 'successful' && res.data) {
        setImage(imageUrl)
        showSnackbar('Image uploaded successfully', 'success')
      } else if (res && res.status === 'failed' && res.message) {
        showSnackbar(res.message, 'error')
      }
      setUploadProgress({ progress: 0, fileName: '' })
      setError(null)
    }
    setIsUploading(false)
  }

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const file = e.target.files[0]
    const validate = await validateProductImage(file)

    if (!validate.valid)
      return setError({
        type: 'image',
        message: validate.message || 'An error occurred.',
      })
    setCropFile(file)
    setShowCropper(true)
  }

  const handleCropComplete = (croppedFile: File): void => {
    handleSave(croppedFile)
    setCropFile(null)
    setShowCropper(false)
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCropFile(null)
  }

  const handleDeleteImage = async (): Promise<void> => {
    if (!image) return

    const fileName = image.split('/')[3]
    const result = await deleteFile(fileName)

    if (result.status === 'successful') {
      const res = organizationId
        ? await updateOrganization(organizationId, { imageUrl: null })
        : { status: 'failed' }

      if (res && res.status === 'successful') {
        showSnackbar('Image deleted successfully', 'success')
      } else if (res && res.status === 'failed' && res.message) {
        showSnackbar(res.message, 'error')
      }
      setImage(null)
    }
  }

  const handleEdit = (): void => {
    setEdit(true)
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_ABOUT_EDIT_CLICKED', {
        organizationId,
      })
    }
  }

  const handleCancel = (): void => {
    setEdit(false)
    setDescriptionValue(description ?? '')
  }

  const handleSubmit = async (): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_ABOUT_SAVE_CLICKED', {
        organizationId,
      })
    }
    setIsSubmitting(true)
    const result = organizationId
      ? await updateOrganization(organizationId, {
          description: descriptionValue,
        })
      : { status: 'failed' }

    if (result && result.status === 'successful' && result.data) {
      setDescriptionValue(result.data.description ?? '')
      showSnackbar('Description updated successfully', 'success')
    } else if (result && result.status === 'failed' && result.message) {
      showSnackbar(result.message, 'error')
    }

    setIsSubmitting(false)
    setEdit(false)
  }

  return (
    <Box>
      <div className="flex items-center">
        <h2 className="text-lg font-semibold ml-4 mr-2">About</h2>
        {!edit && (
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleEdit}
            sx={{
              backgroundColor: '#e0e0e0',
              color: 'text.primary',
              textTransform: 'none',
              padding: '0.1rem 0.75rem',
              ':hover': { backgroundColor: '#bdbdbd' },
            }}
          >
            Edit
          </Button>
        )}
      </div>
      <Card>
        <div className="mb-2">
          <Label text="Description" />
          {edit ? (
            <QuillEditor
              value={descriptionValue}
              onChange={(val) => setDescriptionValue(val)}
              limit={1000}
            />
          ) : (
            <Typography
              fontWeight={700}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(descriptionValue),
              }}
            />
          )}
        </div>
        <div>
          <Label text="Image" />
          {!edit &&
            (image ? (
              <>
                <Link
                  href={image}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {image.split('/')[3]}
                </Link>
                <IconButton
                  onClick={handleDeleteImage}
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            ) : (
              <div>N/A</div>
            ))}
          {edit &&
            (image ? (
              <>
                <Link
                  href={image}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {image.split('/')[3]}
                </Link>
                <IconButton
                  onClick={handleDeleteImage}
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            ) : (
              <div>
                <InputFileUpload
                  name="image"
                  onChange={handleFileChange}
                  buttonText={isUploading ? 'Uploading' : 'Upload File'}
                />
              </div>
            ))}
          {uploadProgress.progress > 0 && uploadProgress.progress < 100 && (
            <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
              {uploadProgress.fileName} - {uploadProgress.progress}%
            </Typography>
          )}
          {error && error.type === 'image' && (
            <p className="text-xs text-red-500 block mt-1">{error.message}</p>
          )}
        </div>
        {edit && (
          <Box className=" mt-6 flex items-center gap-4">
            <Button variant="outlined" size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </Box>
        )}

        {showCropper && cropFile && (
          <Dialog open={showCropper} maxWidth="md" fullWidth>
            <SingleImageCropper
              file={cropFile}
              onCropComplete={handleCropComplete}
              onClose={handleCropCancel}
            />
          </Dialog>
        )}
      </Card>
    </Box>
  )
}

export default About
