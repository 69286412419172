import { ChangeEvent, FC, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Link, Typography } from '@mui/material'

import Card from 'components/card'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { deleteFile, getPresignedUrl, uploadFile } from 'services/files'
import { validateSizeChartFile } from 'utils/validation'

interface SizeChartProps {
  sizeChartUrl?: string
  onSizeChartChange: (f: string | undefined) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const SizeChart: FC<SizeChartProps> = ({ onSizeChartChange, sizeChartUrl }) => {
  const [error, setError] = useState<string>()
  const [uploadProgress, setUploadProgress] = useState({
    fileName: '',
    progress: 0,
  })
  const [isDeleting, setIsDeleting] = useState(false)

  const handleSave = async (file: File): Promise<void> => {
    const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      fileName: file.name,
      progress: 0,
    }))

    const result = await uploadFile(file, signedUrl, (progress) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: progress,
      }))
    })

    if (result.status === 'successful') {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        fileName: '',
        progress: 0,
      }))

      const sizeChartUrl = fileUrl + '/' + uuid

      onSizeChartChange(sizeChartUrl)
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return

    const file = e.target.files[0]

    const validate = validateSizeChartFile(file)

    if (!validate.valid && validate.error) {
      setError(validate.error)
      return
    }

    handleSave(file)
  }

  const onDelete = async (url: string): Promise<void> => {
    setIsDeleting(true)
    const fileName = url.split('/')[3]

    const result = await deleteFile(fileName)

    if (result.status === 'successful') {
      onSizeChartChange(undefined)
    }
    setIsDeleting(false)
  }

  return (
    <div className="mb-5">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mr-4"
      >
        <h2 className="text-lg font-semibold ml-4">Size Chart</h2>
      </Box>

      <Card>
        {sizeChartUrl && (
          <>
            <Link
              href={sizeChartUrl}
              underline="hover"
              target="_blank"
              rel="noopener"
            >
              {sizeChartUrl.split('/')[3]}
            </Link>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => onDelete(sizeChartUrl)}
              className="ml-4 hover:text-red-600"
              disabled={isDeleting}
            >
              <DeleteIcon
                fontSize="inherit"
                className="text-gray-600 hover:text-red-600"
              />
            </IconButton>
          </>
        )}
        {!sizeChartUrl && (
          <InputFileUpload
            id="sizeChart"
            name="sizeChart"
            onChange={handleFileChange}
            buttonText="Upload File"
          />
        )}

        {uploadProgress.fileName !== '' && (
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: 'accents.secondaryAccent',
            }}
          >
            {uploadProgress.fileName} {uploadProgress.progress}
          </Typography>
        )}

        {error && (
          <Typography fontSize={'0.75rem'} color={'error'}>
            *{error}
          </Typography>
        )}
      </Card>
    </div>
  )
}

export default SizeChart
