import { ChangeEventHandler, FC } from 'react'

import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

interface inputFileUploadProps {
  id?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  name?: string
  buttonText: string
  multiple?: boolean
}

const InputFileUpload: FC<inputFileUploadProps> = ({
  id,
  name,
  onChange,
  buttonText,
  multiple,
}): JSX.Element => {
  return (
    <Box>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        size="small"
        disableElevation
        sx={{
          px: 2,
          textTransform: 'none',
          backgroundColor: 'accents.darkGrayAccent',
          color: 'black',
          ':hover': {
            backgroundColor: 'accents.lightGrayAccent',
          },
        }}
      >
        {buttonText}
        <VisuallyHiddenInput
          type="file"
          id={id}
          name={name}
          onChange={onChange}
          multiple={multiple}
        />
      </Button>
    </Box>
  )
}

export default InputFileUpload
