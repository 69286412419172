export function getOperator(op: string): string {
  const operatorList: { [key: string]: string } = {
    '=': 'equals',
    '<': 'lt',
    '<=': 'lte',
    '>': 'gt',
    '>=': 'gte',
    is: 'equals',
    before: 'lt',
    after: 'gt',
    onOrBefore: 'lte',
    onOrAfter: 'gte',
  }

  if (op in operatorList) {
    return operatorList[op]
  }
  return op
}

interface CustomerUrlOptions {
  orgId: number
  cursor?: number
  pageSize?: number
  direction?: 'backward' | 'forward' | ''
  sortField?: string
  sortDirection?: 'asc' | 'desc' | ''
  filterField?: string
  filterValue?: string
  filterOperator?: string
  filterLogicOperator?: 'AND' | 'OR' | ''
}

export interface ProductUrlOptions {
  orgId: number
  cursor?: number
  pageSize?: number
  direction?: 'backward' | 'forward' | ''
}

export const buildCustomerUrl = (
  basePath: string,
  options: CustomerUrlOptions
): string => {
  const queryParams = [`orgId=${options.orgId}`]

  if (options.cursor) queryParams.push(`cursor=${options.cursor}`)
  if (options.pageSize) queryParams.push(`pageSize=${options.pageSize}`)
  if (options.direction) queryParams.push(`direction=${options.direction}`)
  if (options.sortField && options.sortDirection)
    queryParams.push(
      `sortField=${options.sortField}&sortDirection=${options.sortDirection}`
    )

  if (
    options.filterField &&
    options.filterValue &&
    options.filterOperator &&
    options.filterLogicOperator
  ) {
    const filterFieldName =
      options.filterField === 'fullName'
        ? 'firstName,lastName'
        : options.filterField
    const filterLogic =
      options.filterField === 'fullName' ? 'OR' : options.filterLogicOperator
    queryParams.push(
      `filterField=${filterFieldName}&filterValue=${options.filterValue}&filterOperator=${options.filterOperator}&filterLogic=${filterLogic}`
    )
  }

  return `${basePath}?${queryParams.join('&')}`
}

export function roleFormat(role: string): string {
  return role.toLowerCase() === 'admin'
    ? 'Administrator'
    : role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()
}

export const buildProductUrl = (
  basePath: string,
  options: ProductUrlOptions
): string => {
  const queryParams = [`orgId=${options.orgId}`]

  if (options.cursor) queryParams.push(`cursor=${options.cursor}`)
  if (options.pageSize) queryParams.push(`pageSize=${options.pageSize}`)
  if (options.direction) queryParams.push(`direction=${options.direction}`)

  return `${basePath}?${queryParams.join('&')}`
}

export const convertRupeeToPaisa = (price: number | string): number => {
  return Math.round(Number(price) * 100)
}

export const formatPrice = (price: number | undefined | string): string => {
  if (!price) return '₹ 0'

  if (typeof price === 'string') {
    price = parseInt(price)
  }
  return `₹ ${price / 100}`
}
