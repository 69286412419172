import { FC, useEffect, useState } from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import Label from 'components/forms/Label'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { formatPrice } from 'utils/helper'
type VariantType = {
  id?: number
  type: string
  name: string
  price: string | number
  quantity: string | number
  cost: string | number
}

interface VariantsTableProps {
  edit?: boolean
  rowData: VariantType[]
  onRowRemove?: (v: VariantType[]) => void
  onRowAdd?: (v: VariantType[]) => void
}

const VariantsTable: FC<VariantsTableProps> = ({
  edit,
  rowData,
  onRowAdd,
  onRowRemove,
}) => {
  const [rows, setRows] = useState<VariantType[]>([])
  const [error, setError] = useState({
    idx: -1,
    fieldName: '',
    message: '',
  })

  useEffect(() => {
    setRows(rowData)
  }, [rowData])

  const handleRowRemove = (index: number): void => {
    const updatedArray = rows.filter((v, idx) => idx !== index)
    setRows(updatedArray)
    onRowRemove?.(updatedArray)
  }

  const handleRowAdd = (): void => {
    const updatedArray = [
      ...rows,
      {
        type: '',
        name: '',
        price: '',
        quantity: '',
        cost: '',
      },
    ]
    setRows(updatedArray)
  }

  const handleNumberFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, [fieldName]: Number(value) }
      }
      return v
    })
    setRows(data)
    onRowAdd?.(data)
  }

  const handleFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, [fieldName]: value }
      }
      return v
    })
    setRows(data)
    onRowAdd?.(data)
  }

  const handleBlur = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.at(index)

    if (data && value === '') {
      setError({
        idx: index,
        fieldName: fieldName + index,
        message: fieldName + ' is required.',
      })
    } else {
      setError({
        idx: -1,
        fieldName: '',
        message: '',
      })
    }
  }

  return (
    <>
      {!edit &&
        rows.map((data, index) => (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            key={index}
            marginBottom={index < rows.length - 1 ? 2 : 0}
          >
            <Grid item xs={6} sm={3}>
              <Label text="Variant Name" color="secondary" />

              <Typography
                fontWeight={700}
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                {data.name}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Type" color="secondary" />

              <Typography
                fontWeight={700}
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                {data.type?.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Selling Price" color="secondary" />

              <Typography
                fontWeight={700}
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                &#8377;{data.price}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Quantity in Stock" color="secondary" />

              <Typography
                fontWeight={700}
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                {data.quantity}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Cost Per Unit" color="secondary" />

              <Typography
                fontWeight={700}
                sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}
              >
                {data.cost ? formatPrice(data.cost) : 'N/A'}
              </Typography>
            </Grid>
            {edit && (
              <Grid item xs={1} alignSelf="center">
                <IconButton onClick={() => handleRowRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}

      {edit &&
        rows.map((variant, index) => (
          <Grid container key={index} spacing={2}>
            <Grid item xs={6} md={3}>
              <Label text="Name*" />
              <CustomTextField
                name={`name${index}`}
                value={variant.name}
                onChange={(e) =>
                  handleFieldChange(e.target.value, index, 'name')
                }
                onBlur={(e) => handleBlur(e.target.value, index, 'name')}
                error={Boolean(error.fieldName === `name${index}`)}
                helperText={error.fieldName === `name${index}` && error.message}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Label text="Type*" />
              <TextField
                select
                fullWidth
                size="small"
                name={`type${index}`}
                sx={{ marginTop: 0 }}
                SelectProps={{ style: { backgroundColor: 'white' } }}
                variant="outlined"
                margin="normal"
                value={variant.type}
                onChange={(e) =>
                  handleFieldChange(e.target.value, index, 'type')
                }
                error={Boolean(error.fieldName === `type${index}`)}
                helperText={error.fieldName === `type${index}` && error.message}
              >
                <MenuItem value={'color'}>Color</MenuItem>
                <MenuItem value={'size'}>Size</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Price*" />
              <CustomTextField
                type="number"
                min={0}
                name={`price` + index}
                value={variant.price.toString()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  handleNumberFieldChange(e.target.value, index, 'price')
                }
                onBlur={(e) => handleBlur(e.target.value, index, 'price')}
                error={Boolean(error.fieldName === `price${index}`)}
                helperText={
                  error.fieldName === `price${index}` && error.message
                }
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Quantity" />
              <CustomTextField
                name={`quantity${index}`}
                min={0}
                type="number"
                value={variant.quantity.toString()}
                onChange={(e) =>
                  handleNumberFieldChange(e.target.value, index, 'quantity')
                }
                onBlur={(e) => handleBlur(e.target.value, index, 'quantity')}
                error={Boolean(error.fieldName === `quantity${index}`)}
                helperText={
                  error.fieldName === `quantity${index}` && error.message
                }
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <Label text="Cost" />
              <CustomTextField
                name={`cost${index}`}
                type="number"
                min={0}
                value={variant.cost ? variant.cost.toString() : ''}
                onChange={(e) =>
                  handleNumberFieldChange(e.target.value, index, 'cost')
                }
                onBlur={(e) => handleBlur(e.target.value, index, 'cost')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={Boolean(error.fieldName === `cost${index}`)}
                helperText={error.fieldName === `cost${index}` && error.message}
              />
            </Grid>
            {index > 0 && (
              <Grid item alignSelf={'center'}>
                <IconButton onClick={() => handleRowRemove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      {edit && (
        <Button sx={{ fontSize: '0.8rem', mt: 1 }} onClick={handleRowAdd}>
          + Add more variant
        </Button>
      )}
    </>
  )
}

export default VariantsTable
