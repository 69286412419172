import { getToken } from 'utils/authCookies'

import { CreateCategoryData, UpdateCategoryData } from './index.type'

const productBasePath = `${process.env.REACT_APP_API_BASE_PATH}/products`
export const createNewCategory = async (createData: CreateCategoryData) => {
  const token = await getToken()

  try {
    const res = await fetch(`${productBasePath}/category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createData),
    })

    const { data } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    } else {
      return { status: 'failed', message: 'Failed to create category' }
    }
  } catch (error) {
    console.error('Error creating category:', error)
    return { status: 'failed', message: 'An error occurred' }
  }
}

export const getCategoryById = async (
  organizationId: string,
  categoryId: string
) => {
  const token = await getToken()

  try {
    const res = await fetch(
      `${productBasePath}/category/${organizationId}/${categoryId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { data } = await res.json()

    if (res.ok) {
      return { status: 'successful', data: data }
    } else {
      return { status: 'failed', message: 'Failed to fetch category by ID' }
    }
  } catch (error) {
    console.error('Error fetching category by ID:', error)
    return { status: 'failed', message: 'An error occurred' }
  }
}

export const updateExistingCategory = async (
  categoryId: string,
  updateData: UpdateCategoryData
) => {
  const token = await getToken()

  try {
    const res = await fetch(`${productBasePath}/category/${categoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateData),
    })

    const { data } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    } else {
      return { status: 'failed', message: 'Failed to update category' }
    }
  } catch (error) {
    console.error('Error updating category:', error)
    return { status: 'failed', message: 'An error occurred' }
  }
}
