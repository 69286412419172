import { FC } from 'react'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, IconButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { format } from 'date-fns'

import { StyledDataGrid } from 'pages/dashboard/components/StyledDataGrid'
import { Order } from 'services/orders/index.type'
import { formatPrice } from 'utils/helper'

interface OrdersListProps {
  list: Order[]
}

const OrdersList: FC<OrdersListProps> = ({ list }) => {
  const handleRowClick = (id: number): void => {
    // Navigate to the detail page using only the row ID
    window.open(`/dashboard/orders/${id}`, '_blank')
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      resizable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 70,
    },
    {
      field: 'shippingAddress',
      headerName: 'Shipping Address',
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Order Date',
      type: 'dateTime',
      valueFormatter: (value: string): string => {
        return format(new Date(value), 'MMMM d, yyyy')
      },
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'phoneNo',
      type: 'number',
      headerName: 'Phone Number',
      sortable: false,
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'totalPrice',
      type: 'number',
      headerName: 'Total Amount',
      valueFormatter: (value: number): number => {
        return value ?? 0
      },
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'navigate',
      headerName: '',
      align: 'center',
      flex: 0.7,
      renderCell: (params) => (
        <IconButton onClick={() => handleRowClick(params.id as number)}>
          <OpenInNewIcon fontSize="small" color="primary" />
        </IconButton>
      ),
    },
  ]

  const rows = list?.map((rowData: Order) => {
    const { address, city, country, pinCode, state } = rowData.shippingAddress

    return {
      id: rowData.id,
      shippingAddress: `${address}, ${city}, ${state}, ${country}-${pinCode}`,
      createdAt: rowData.createdAt,
      phoneNo: rowData.phoneNo,
      status: rowData.status,
      totalPrice: formatPrice(rowData.totalPrice),
    }
  })

  return (
    <Box
      className="bg-white"
      sx={{ width: { sm: 'calc(100vw - 340px)', md: 'calc(100vw - 370px)' } }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 4 } },
        }}
        pageSizeOptions={[4]}
        disableColumnMenu
        pagination
      />
    </Box>
  )
}

export default OrdersList
