import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Box, Button, InputAdornment, Typography } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import DateRangeInput from 'pages/dashboard/components/DateRangeInput'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'services/products/index.types'
import { shortDateFormat } from 'utils/dateFormat'
import { convertRupeeToPaisa, formatPrice } from 'utils/helper'

interface PricingDetailsProps {
  productId: number
  price: number
  discountedPrice: number | null
  discountPercentage: number | null
  offerValidity: [Date, Date]
  onUpdate: (data?: Product) => void
}

interface FormType {
  discountedPrice: string
  discountPercentage: string
  offerValidity: [Date | null, Date | null]
}

const OffersDetails: FC<PricingDetailsProps> = ({
  productId,
  price,
  discountedPrice,
  discountPercentage,
  offerValidity,
  onUpdate,
}) => {
  const [values, setValues] = useState<FormType>({
    discountPercentage: '',
    discountedPrice: '',
    offerValidity: [null, null],
  })
  const [error, setError] = useState({
    discountPercentage: '',
    discountedPrice: '',
  })
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setValues({
      discountPercentage: discountPercentage
        ? discountPercentage.toString()
        : '',
      discountedPrice:
        typeof discountedPrice === 'number'
          ? (discountedPrice / 100).toString()
          : '',
      offerValidity: offerValidity.length > 0 ? offerValidity : [null, null],
    })
  }, [discountPercentage, discountedPrice, offerValidity])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'discountPercentage') {
      const disPrice = (price - (+e.target.value * price) / 100) / 100
      setValues((prev) => ({
        ...prev,
        discountPercentage: e.target.value,
        discountedPrice: disPrice.toFixed(0),
      }))
    } else {
      const percentage =
        e.target.value !== ''
          ? ((price - convertRupeeToPaisa(e.target.value)) / price) * 100
          : 0

      setValues((prev) => ({
        ...prev,
        discountedPrice: e.target.value,
        discountPercentage: percentage.toFixed(2),
      }))

      if (+e.target.value > price) {
        setError((prev) => ({
          ...prev,
          discountedPrice: '*Should be less than price',
        }))
      } else {
        setError({ discountPercentage: '', discountedPrice: '' })
      }
    }
  }

  const handleDateChange = (update: [Date | null, Date | null]): void => {
    setValues((prev) => ({ ...prev, offerValidity: update }))
  }

  const handleSave = async (): Promise<void> => {
    if (values.discountPercentage === '' || values.discountedPrice === '') {
      setError({
        discountPercentage:
          values.discountPercentage === '' ? '*Price is required.' : '',
        discountedPrice:
          values.discountedPrice === '' ? '*DiscountedPrice is required.' : '',
      })
      return
    }

    if (+price < +values.discountedPrice) return

    const newdiscountedPricePaisa = convertRupeeToPaisa(values.discountedPrice)

    if (
      +values.discountPercentage === discountPercentage &&
      newdiscountedPricePaisa === discountedPrice &&
      values.offerValidity[0] === offerValidity[0] &&
      values.offerValidity[1] === offerValidity[1]
    ) {
      setEdit(false)
      return
    }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_Offers_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: {
        discountedPrice: newdiscountedPricePaisa,
        discountPercentage: +values.discountPercentage,
        offerValidity: values.offerValidity.includes(null)
          ? undefined
          : values.offerValidity,
      },
    })

    if (res.status === 'successful') {
      showSnackbar('Product offers updated.', 'success')
      onUpdate(res.data)
    }
    setIsSubmitting(false)
    setEdit(!edit)
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleCancel = (): void => {
    setValues({
      discountPercentage: discountPercentage
        ? discountPercentage.toString()
        : '',
      discountedPrice: discountedPrice
        ? (discountedPrice / 100).toString()
        : '',
      offerValidity: offerValidity.length > 0 ? offerValidity : [null, null],
    })
    setEdit(false)
  }

  const offerStartDate =
    offerValidity && offerValidity.length > 0
      ? shortDateFormat(offerValidity[0].toString())
      : undefined
  const offerEndDate =
    offerValidity && offerValidity.length > 0
      ? shortDateFormat(offerValidity[1].toString())
      : undefined

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader
          title="Offers & Discounts"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>

      <Card>
        <Box display={'flex'} columnGap={2} rowGap={1}>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Discounted Price
            </Typography>
            {edit ? (
              <CustomTextField
                name="discountedPrice"
                type="number"
                value={values.discountedPrice ?? ''}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={error.discountedPrice !== ''}
                helperText={error.discountedPrice}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {discountedPrice ? formatPrice(discountedPrice) : 'N/A'}
              </Typography>
            )}
          </Box>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Discount Percentage
            </Typography>
            {edit ? (
              <CustomTextField
                name="discountPercentage"
                type="number"
                value={values.discountPercentage ?? ''}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={Boolean(error.discountPercentage)}
                helperText={error.discountPercentage}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {discountPercentage ?? 'N/A'}
              </Typography>
            )}
          </Box>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Offer Valid Till
            </Typography>
            {edit ? (
              <DateRangeInput
                name="offerValidTill"
                value={values.offerValidity}
                onChange={handleDateChange}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {offerStartDate} - {offerEndDate}
              </Typography>
            )}
          </Box>
        </Box>
        {edit && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default OffersDetails
